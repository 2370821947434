import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';

import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import NicImg from '../../assets/img/logo-nicbr-topo.png'
import CgiImg from '../../assets/img/logo-cgibr-topo.png'
//import YtIcon from '../../assets/img/icon-youtube-header.png'
//import TwitterIcon from '../../assets/img/icon-twitter-header.png'
//import FacebookIcon from  '../../assets/img/icon-facebook-header.png'

import {Nav, Dropdown} from 'react-bootstrap';


function PageHeader(){
	const [size, setSize] = useState(window.innerWidth);

	useEffect(() => {
		function handleResize() {
			setSize(window.innerWidth)
		}

		window.addEventListener('resize', handleResize)
	})

	return(
		<div id="sidebar">
			<div id="sidebar-menu">
			<div className="py-3 px-2 first-line">
				Mapa de AS <span style={{fontSize: 12 + 'px', fontStyle: 'italic'}}>(beta)</span>
			</div>
				<Nav variant="tabs" className="flex-column py-3">
					<Dropdown>
						<Dropdown.Toggle id="dropdown-basic">
							Mapa
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item as="span"><Link to="/"><Nav.Link as="span">Sede do AS</Nav.Link></Link></Dropdown.Item>
							<Dropdown.Item as="span"><Link to='/PTTs'><Nav.Link as="span">Presença nos PTTs</Nav.Link></Link></Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					<Dropdown>
						<Dropdown.Toggle id="dropdown-basic">
							Distribuição
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item as="span"><Link to="/graficos"><Nav.Link as="span">Região e Estado</Nav.Link></Link></Dropdown.Item>
							<Dropdown.Item as="span"><Link to="/tabela"><Nav.Link as="span">Cidade</Nav.Link></Link></Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					<Dropdown>
						<Dropdown.Toggle id="dropdown-basic">
							Localizador
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item as="span"><Link to="/localizador"><Nav.Link as="span">ASNs</Nav.Link></Link></Dropdown.Item>
							<Dropdown.Item as="span"><Link to="/geoIP"><Nav.Link as="span">Blocos IP</Nav.Link></Link></Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
					<Link to="/FAQ"><Nav.Link as="span">FAQ</Nav.Link></Link>
					{size > 767 && 
					<div id="logos">
						<div className="pt-3 pb-0">
							<a href="https://nic.br" target="_blank" rel="noopener noreferrer"><img src={NicImg} alt="Logo NIC.Br" className="img-fluid" /></a>
							<a href="https://cgi.br" target="_blank" rel="noopener noreferrer"><img src={CgiImg} alt="Logo CGI.Br" className="img-fluid" /></a>
						</div>
					</div> 
					}
				</Nav>
			
			</div>
		</div>
		/*
			<div id="logos" className="text-center">
				<div className="pr-1">
					<a href="https://nic.br" target="_blank" rel="noopener noreferrer"><img className="mr-3" src={NicImg} alt="Logo NIC.Br" className="img-fluid" /></a>
				</div>
				<div className="pl-1">
					<a href="https://cgi.br" target="_blank" rel="noopener noreferrer"><img src={CgiImg} alt="Logo CGI.Br" className="img-fluid" /></a>
				</div>
			</div>
		<div>
			<div className="py-3 px-2 first-line">
				Mapa de AS <span style={{fontSize: 12 + 'px', fontStyle: 'italic'}}>(beta)</span>
			</div>
			<Nav variant="tabs" className="flex-column">
				<Nav.Item><Nav.Link href="/">Mapa</Nav.Link></Nav.Item>
				<Nav.Item><Nav.Link href="/graficos">Gráficos</Nav.Link></Nav.Item>
				<Nav.Item><Nav.Link href="/tabela">Tabela</Nav.Link></Nav.Item>
				<Nav.Item><Nav.Link href="/localizador">Localizador</Nav.Link></Nav.Item>
				<Nav.Item><Nav.Link href="/PTTs">PTTs</Nav.Link></Nav.Item>
				<Nav.Item><Nav.Link href="/geoIP">GeoIP</Nav.Link></Nav.Item>
			</Nav>
		</div>
		/*
		<header>
			<Navbar variant="dark" className="first-line justify-content-end">
				<a href="https://nic.br" target="_blank" rel="noopener noreferrer"><img className="mr-3" src={NicImg} alt="Logo NIC.Br" /></a>
				<a href="https://cgi.br" target="_blank" rel="noopener noreferrer"><img src={CgiImg} alt="Logo CGI.Br" /></a>
			 </Navbar>
			<Navbar collapseOnSelect expand="lg" className="second-line" variant="dark">
			  <Navbar.Brand href="/">Mapa de AS</Navbar.Brand>
			  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
			  <Navbar.Collapse id="responsive-navbar-nav">
			    <Nav>
			      <Nav.Item><Nav.Link href="/">Mapa</Nav.Link></Nav.Item>
			      <Nav.Item><Nav.Link href="/graficos">Gráficos</Nav.Link></Nav.Item>
			      <Nav.Item><Nav.Link href="/tabela">Tabela</Nav.Link></Nav.Item>
			      <Nav.Item><Nav.Link href="/localizador">Localizador</Nav.Link></Nav.Item>
			      <Nav.Item><Nav.Link href="/PTTs">PTTs</Nav.Link></Nav.Item>
			      <Nav.Item><Nav.Link href="/geoIP">GeoIP</Nav.Link></Nav.Item>
			    </Nav>
			  </Navbar.Collapse>
			</Navbar>
		</header>
		*/
	)
}

export default PageHeader;