import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import $ from 'jquery';

import {template} from '../../uteis/funcoes';
 


class TabelaLocalizador extends React.Component {

  componentDidMount(){
    $(window).width() < 551 ? document.getElementById("controllers").classList.add("w-100", "paginationjs-small", "justify-content-center") : document.getElementById("controllers").classList.add("w-75", "paginationjs-big", "justify-content-end");
    var total = this.props.loc_total
    window.$('#controllers').pagination({
      dataSource: this.props.array_pagination,
      pageSize: 20,
      pageRange: 1,
      showGoInput: true,
      formatGoInput: 'Ir para: <%= input %>',
      callback: function(data, pagination) {
        var html = template(data, "CIDADE", "NÚMERO DE ASNs", "DISTÂNCIA(km)", total)
        window.$(".dataContainer").html(html);
      }
    })
  }

  render(){
    return(      
      <div id="localizador" className="table-responsive mt-4">      
        <div className="dataContainer">

        </div>
        <div id="controllers" className="paginationjs paginationjs-theme-black mx-auto my-2 d-flex"></div>
      </div>
    )
  }
}


export default TabelaLocalizador;