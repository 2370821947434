import React from 'react';
import api from '../../services/api';

import $ from 'jquery';

import PageHeader from '../../components/PageHeader/PageHeader';


import {Container, Col, Row} from 'react-bootstrap';

import './styles.css'

import {template} from '../../uteis/funcoes';

window.$(window).resize(() => {
	try {
		var controllersElement = document.getElementById("controllers");

		if(window.$(window).width() < 581){
			controllersElement.classList.remove("w-75", "paginationjs-big", "justify-content-end");
			controllersElement.classList.add("w-100", "paginationjs-small", "justify-content-center");
		}
		else if (window.$(window).width() >= 551){
			controllersElement.classList.remove("w-100", "paginationjs-small", "justify-content-center");
			controllersElement.classList.add("w-75", "paginationjs-big", "justify-content-end");
		}	
	} catch (error) {
		
	}
})

class Tabela extends React.Component {
	constructor(){
		super()

		this.state={
			itens: []
		}
	}

	componentDidMount(){
		api.get('/buscatabela/').then(response => {
			var cidades=response.data
			var e=[];
			for(var a in cidades){
				var l=[cidades[a][2],cidades[a][0],cidades[a][1]];e.push(l)
			}
			for(var s=[],i=(e=e.sort(function(e,a){
				if(e[2]!==a[2])return a[2]-e[2]
			})).length,r=0;r<3;r++){for(var t=[],o=0;o<i;o++){var c=e[o][r];t.push(c)}s.push(t)}
	
			$(window).width() < 551 ? document.getElementById("controllers").classList.add("w-100", "paginationjs-small", "justify-content-center") : document.getElementById("controllers").classList.add("w-75", "paginationjs-big", "justify-content-end");
			
			var array_pagination = [];
	
			for(var indice=0; indice < s[0].length; indice++){
				array_pagination.push([s[0][indice], s[1][indice], s[2][indice]]);
			}
	
			window.$('#controllers').pagination({
				dataSource: array_pagination,
				pageSize: 100,
				pageRange: 1,
				showGoInput: true,
				formatGoInput: 'Ir para: <%= input %>',
				callback: function(data, pagination) {
					var html = template(data, "CIDADE", "ESTADO", "QUANTIDADE");
					$(".dataContainer").html(html);
				}
			})
		}).catch(error => {
			alert("Erro ao buscar dados para a tabela.")
			return
		  })
	}

  	render(){
		return(
			<div>
				<Container className="m-0 p-0" fluid>
					<Row className="m-0">
						<Col className=" p-0 menu text-center">
							<PageHeader />
						</Col>
						<Col xl={10} lg={10} md={10} className="p-0">
							<div id="cidades" className="table-responsive mt-4">

								<div className="dataContainer"></div>
								<div id="controllers" className="paginationjs paginationjs-theme-black mx-auto my-2 d-flex"></div>
							</div>
						</Col>
					</Row>
				</Container>
		  	</div>
		)
  	}
}

export default Tabela;