import React from 'react';
import api from '../../services/api'

import PageHeader from '../../components/PageHeader/PageHeader';
import Mapa from '../../components/Mapa/Mapa';

import L from 'leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Marker, Popup, LayerGroup, Circle} from 'react-leaflet';

import {Container, Col, Row} from 'react-bootstrap';

import { blueMarker, redMarker, greenMarker } from '../../components/CustomClustersMarkers/index'

import { clusterMarkersPtt1, clusterMarkersPtt2, clusterMarkersPtt3 } from '../../components/CustomClustersMarkers/index'

import './styles.css'

var icon_ptt = new L.Icon({
	iconUrl: require('../../assets/img/ptt.png'),
	iconSize: [16, 16]
})

// Função para criar os ClusterMarkers após um PTT ser selecionado no filtro
function createClustersMarkers(asns_ptt=null, asns_ptt2=null){
	if([asns_ptt, asns_ptt2].includes(null)){
		return
	}

	var media_lat = 0;
	var media_lng = 0;
	var qtde_coord = 0;

	var hash_asns_ptt1 = asns_ptt.map(asn => { return asn[3] });

	var newClusterGroupPtt1 = [];
	var newCircleCusterPtt1 = [];
	var remove_asns_ptt1 = [];

	var newClusterGroupPtt2 = [];
	var newCircleCusterPtt2 = [];
	
	var asns_ptt3 = [];
	var newClusterGroupPtt3 = [];
	var newCircleCusterPtt3 = [];

	for(var asn_ptt2 in asns_ptt2){
		var asn = asns_ptt2[asn_ptt2];

		var lat = parseFloat(asn[1]);
		var lng = parseFloat(asn[2]);

		if(hash_asns_ptt1.includes(asn[3])){

			remove_asns_ptt1.push(asn[3]);
			asns_ptt3.push(asn);

			media_lat += lat;
			media_lng += lng;
			qtde_coord += 1;
			
			newClusterGroupPtt3.push(
				<Marker key={asn[3]} position={[lat, lng]} icon={greenMarker}>
					<Popup>
						<b><p>as{asn[0]}</p></b>
					</Popup>
				</Marker>
			)

			newCircleCusterPtt3.push(
				<Circle key={asn[3]} center={[lat, lng]} radius={10000} fillColor='green' color='green' opacity={0} fillOpacity={0.2}>
					<Popup>
						<b><p>as{asn[0]}</p></b>
					</Popup>
				</Circle>
			)
		} else {
			media_lat += lat;
			media_lng += lng;
			qtde_coord += 1;

			newCircleCusterPtt2.push(
				<Circle key={asn[3]} center={[lat, lng]} radius={10000} fillColor='red' color='red' opacity={0} fillOpacity={0.2}>
					<Popup>
						<b><p>as{asn[0]}</p></b>
					</Popup>
				</Circle>
			)

			newClusterGroupPtt2.push(
				<Marker key={asn[3]} position={[lat, lng]} icon={redMarker}>
					<Popup>
						<b><p>as{asn[0]}</p></b>
					</Popup>
				</Marker>
			)
		}
	}
	for(var asn_ptt1 in asns_ptt){
		var new_asn_ptt1 = asns_ptt[asn_ptt1];
		if(!(remove_asns_ptt1.includes(new_asn_ptt1[3]))){

			lat = parseFloat(new_asn_ptt1[1]);
			lng = parseFloat(new_asn_ptt1[2]);

			media_lat += lat;
			media_lng += lng;
			qtde_coord += 1;

			//alert(new_asn_ptt1[3])
			newCircleCusterPtt1.push(
				<Circle key={new_asn_ptt1[3]} center={[lat, lng]} radius={10000} fillColor='blue' color='blue' opacity={0} fillOpacity={0.2}>
					<Popup>
						<b><p>as{new_asn_ptt1[0]}</p></b>
					</Popup>
				</Circle>
			)

			newClusterGroupPtt1.push(
				<Marker key={new_asn_ptt1[3]} position={[lat, lng]} icon={blueMarker}>
					<Popup>
						<b><p>as{new_asn_ptt1[0]}</p></b>
					</Popup>
				</Marker>
			)
		}
	}

	newCircleCusterPtt1 = <LayerGroup key="1">{newCircleCusterPtt1}</LayerGroup>
	newCircleCusterPtt2 = <LayerGroup key="2">{newCircleCusterPtt2}</LayerGroup>
	newCircleCusterPtt3 = <LayerGroup key="3">{newCircleCusterPtt3}</LayerGroup>

	newClusterGroupPtt1 = <MarkerClusterGroup key="1" iconCreateFunction={clusterMarkersPtt1}>{newClusterGroupPtt1}</MarkerClusterGroup>
	newClusterGroupPtt2 = <MarkerClusterGroup key="2" iconCreateFunction={clusterMarkersPtt2}>{newClusterGroupPtt2}</MarkerClusterGroup>
	newClusterGroupPtt3 = <MarkerClusterGroup key="3" iconCreateFunction={clusterMarkersPtt3}>{newClusterGroupPtt3}</MarkerClusterGroup>

	return [[asns_ptt, newClusterGroupPtt1, newCircleCusterPtt1], [asns_ptt2, newClusterGroupPtt2, newCircleCusterPtt2], [asns_ptt3, newClusterGroupPtt3, newCircleCusterPtt3], [media_lat, media_lng, qtde_coord]]
}

class PTTs extends React.Component {
	constructor(){
		super()

		this.state = {
			lat: -13.7059886,
			lng: -69.6447028,
			zoom: 3,
			ptts: [],
			markerClusterPtt1: {
				asns: [],
				markerCluster: [],
				circleMarker: []
			},
			markerClusterPtt2: {
				asns: [],
				markerCluster: [],
				circleMarker: []
			},
			markerClusterPtt3: {
				asns: [],
				markerCluster: [],
				circleMarker: []
			},
			plotData: []
		}
	}

	AlternarVisualizacao = (e) => {
		if(e.target.value === 'selecione' || (this.state.markerClusterPtt1.asns.length === 0 && this.state.markerClusterPtt2.asns.length === 0)){
			return
		}

		var plotData = [];

		if(e.target.value === 'marker'){
			plotData = [this.state.markerClusterPtt1.markerCluster, this.state.markerClusterPtt2.markerCluster, this.state.markerClusterPtt3.markerCluster]
			this.setState({
				plotData: plotData
			})
			return
		}
		plotData = [this.state.markerClusterPtt1.circleMarker, this.state.markerClusterPtt2.circleMarker, this.state.markerClusterPtt3.circleMarker]
		this.setState({
			plotData: plotData
		})

		return
	}

	FiltroPTT = (e) => {
		
		if(e.target.id === "btn_limparFiltro"){
			this.setState({
				markerClusterPtt1: {
					asns: [],
					markerCluster: [],
					circleMarker: []
				},
				markerClusterPtt2: {
					asns: [],
					markerCluster: [],
					circleMarker: []
				},
				markerClusterPtt3: {
					asns: [],
					markerCluster: [],
					circleMarker: []
				},
				plotData: []
			})
			document.getElementById('ptt').value = 'selecione';
			document.getElementById('ptt2').value = 'selecione';
			document.getElementById("optPlotagem").value = 'marker';
			return
		}

		var sigla = e.target.value;
		var plotOption = document.getElementById("optPlotagem").value;
		var select_ptt2 = document.getElementById('ptt2').value;

		var media_lat = 0;
		var media_lng = 0;
		var qtde_coord = 0;

		if(sigla !== 'selecione'){
			if(e.target.id === "ptt"){
				api.get("/buscaASNsPTT/" + sigla + "/").then(response => {
					var asns_ptt = response.data;
					if (asns_ptt.length == 0){
						alert('Nenhum ASN conectado ao PTT selecionado na primeira opção!')
						return
					}
					var clusterGroup = asns_ptt.map(asn => {
						media_lat += parseFloat(asn[1])
						media_lng += parseFloat(asn[2])
						qtde_coord += 1
						return(
							<Marker key={asn[3]} position={[parseFloat(asn[1]), parseFloat(asn[2])]} icon={blueMarker}>
								<Popup>
									<b><p>as{asn[0]}</p></b>
								</Popup>
							</Marker>
						)
					});

					var circleMarkers = asns_ptt.map(asn => {
						return(
							<Circle key={asn[3]} center={[parseFloat(asn[1]), parseFloat(asn[2])]} radius={10000} fillColor='blue' color='blue' opacity={0} fillOpacity={0.2}>
								<Popup>
									<b><p>as{asn[0]}</p></b>
								</Popup>
							</Circle>
						)
					});

					if(select_ptt2 === 'selecione'){
						circleMarkers = <LayerGroup>{circleMarkers}</LayerGroup>
						clusterGroup = <MarkerClusterGroup iconCreateFunction={clusterMarkersPtt1}>{clusterGroup}</MarkerClusterGroup>

						var plotData = plotOption === "circulo" ? circleMarkers : clusterGroup;

						media_lat = media_lat /= qtde_coord;
						media_lng = media_lng /= qtde_coord;
						
						this.setState({
							lat: media_lat,
							lng: media_lng,
							zoom: 6,
							markerClusterPtt1: {
								asns: asns_ptt,
								markerCluster: clusterGroup,
								circleMarker: circleMarkers
							},
							plotData: plotData
						})
						return
					}
					else {
						api.get("/buscaASNsPTT/" + select_ptt2 + "/").then(response_ptt2 => {

							var asns_ptt2 = response_ptt2.data;
							
							var newClustersMarkers = createClustersMarkers(asns_ptt, asns_ptt2);
	
							media_lat = newClustersMarkers[3][0];
							media_lng = newClustersMarkers[3][1];
							qtde_coord = newClustersMarkers[3][2];
	
							media_lat = media_lat /= qtde_coord;
							media_lng = media_lng /= qtde_coord;

							var plotDataMarker = [ newClustersMarkers[0][1], newClustersMarkers[1][1], newClustersMarkers[2][1] ]
							var plotDataCirculo = [ newClustersMarkers[0][2], newClustersMarkers[1][2], newClustersMarkers[2][2] ]

							var plotData = plotOption === "circulo" ? plotDataCirculo : plotDataMarker;
	
							this.setState({
								lat: media_lat,
								lng: media_lng,
								zoom: 6,
								markerClusterPtt1: {
									asns: newClustersMarkers[0][0],
									markerCluster: newClustersMarkers[0][1],
									circleMarker: newClustersMarkers[0][2],
								},
								markerClusterPtt2: {
									asns: newClustersMarkers[1][0],
									markerCluster: newClustersMarkers[1][1],
									circleMarker: newClustersMarkers[1][2],
								},
								markerClusterPtt3: {
									asns: newClustersMarkers[2][0],
									markerCluster: newClustersMarkers[2][1],
									circleMarker: newClustersMarkers[2][2],
								},
								plotData: plotData
							})
							return
						}).catch(error => {
							alert("Erro ao buscar dados do(s) PTT(s).")
							return
						})
					}
				}).catch(error => {
					alert("Erro ao buscar dados do(s) PTT(s).")
					return
				})
			}
			else if (e.target.id === "ptt2" && this.state.markerClusterPtt1.asns.length !== 0){
				api.get('/buscaASNsPTT/' + document.getElementById("ptt2").value + '/').then(response => {

					var asns_ptt1 = this.state.markerClusterPtt1.asns;
					var asns_ptt2 = response.data;
					
					var newClustersMarkers = createClustersMarkers(asns_ptt1, asns_ptt2);

					media_lat = newClustersMarkers[3][0];
					media_lng = newClustersMarkers[3][1];
					qtde_coord = newClustersMarkers[3][2];

					media_lat = media_lat /= qtde_coord;
					media_lng = media_lng /= qtde_coord;

					var plotDataMarker = [ newClustersMarkers[0][1], newClustersMarkers[1][1], newClustersMarkers[2][1] ]
					var plotDataCirculo = [ newClustersMarkers[0][2], newClustersMarkers[1][2], newClustersMarkers[2][2] ]

					var plotData = plotOption === "circulo" ? plotDataCirculo : plotDataMarker;

					this.setState({
						lat: media_lat,
						lng: media_lng,
						zoom: 6,
						markerClusterPtt1: {
							asns: newClustersMarkers[0][0],
							markerCluster: newClustersMarkers[0][1],
							circleMarker: newClustersMarkers[0][2],
						},
						markerClusterPtt2: {
							asns: newClustersMarkers[1][0],
							markerCluster: newClustersMarkers[1][1],
							circleMarker: newClustersMarkers[1][2],
						},
						markerClusterPtt3: {
							asns: newClustersMarkers[2][0],
							markerCluster: newClustersMarkers[2][1],
							circleMarker: newClustersMarkers[2][2],
						},
						plotData: plotData
					})

					return

				}).catch(error => {
					alert("Erro ao buscar dados do(s) PTT(s).")
					return
				})
			}
			return
		}
	}

	async componentDidMount(){
		await api.get('/buscaPTTs/').then(response => {
			var ptts = response.data
			var layer_group_ptts = <LayerGroup>{
						ptts.map(ptt => {
							return (
								<Marker key={ptt[0]} position={[parseFloat(ptt[1]), parseFloat(ptt[2])]} icon={icon_ptt}>
									<Popup>
										<b><a className="link_ptts" href={"https://ix.br/trafego/agregado/" + ptt[0]} target="__blank">IX.br {ptt[0].toUpperCase()}</a></b>
									</Popup>
								</Marker>
							)
						})
					}</LayerGroup>

			this.setState({
				ptts: layer_group_ptts
			})
		}).catch(error => {
			alert("Erro ao buscar dados dos PTTs.")
			return
		})
	}

	render(){
		return(
			<div>
				<Container className="m-0 p-0" fluid>
					<Row className="m-0">
						<Col className=" p-0 menu text-center">
							<PageHeader/>
						</Col>
						<Col xl={10} lg={10} md={10} className="p-0">
							<Mapa
								page="PTTs"
								ptts={this.state.ptts}
								dataMap={[
									this.state.lat,
									this.state.lng,
									this.state.zoom
								]}
								funcaoPTT={this.FiltroPTT}
								funcaoVisualizacao={this.AlternarVisualizacao}
								plotData={this.state.plotData}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

export default PTTs;