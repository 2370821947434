export function maiusculas(string) {
  var palavras = string.split(" ")
  var txt = ""
  palavras.forEach(function(value,idx) { 
    if ((idx > 0 && value.length <= 3 && value.charAt(0) === "d") || value === "e")  {
      txt = txt + value + " "
    } else {
      var comHifen = value.split("-")
      var auxNome = ""
      if (comHifen.length > 1) {
        comHifen.forEach(function(vv,ii) {
          if (ii === 0) {
            auxNome = vv.charAt(0).toUpperCase() + vv.slice(1)
          } else if ( ii < comHifen.length ) {
            auxNome = auxNome + "-" + vv.charAt(0).toUpperCase() + vv.slice(1)
          }
        })
        txt = txt + auxNome + " "
        
      } else {
        txt = txt + value.charAt(0).toUpperCase() + value.slice(1) + " "
      }
    }
  })
  return txt.trim()
}

export function template(s, a, b, c, loc_total=-1) {

  var tabela = document.createElement('table');
  tabela.className = "table table-striped table-bordered w-75 m-auto text-center"

  var thead = document.createElement('thead');
  thead.className = "thead_table thead-dark text-light";
  
  var trHeader = document.createElement('tr');
  
  var thA = document.createElement('th');
  thA.innerText = a;
  
  var thB = document.createElement('th');
  thB.innerText = b;
  
  var thC = document.createElement('th');
  thC.innerText = c;
  
  trHeader.appendChild(thA);
  trHeader.appendChild(thB);
  trHeader.appendChild(thC);
  
  if (loc_total > -1) {
    var th_totAsns = document.createElement('th');
    th_totAsns.className = "loc_total";
    th_totAsns.colSpan = 3;
    th_totAsns.appendChild(document.createTextNode("Total de ASNs: " + loc_total));
    thead.appendChild(th_totAsns);
  }
  
  thead.appendChild(trHeader);

  tabela.appendChild(thead);
  
  var tbody = document.createElement('tbody');

  for(var indice=0; indice < s.length; indice++){
    var nome = maiusculas(s[indice][0])
    var tr = document.createElement('tr');

    var td_a = document.createElement('td');
    td_a.appendChild(document.createTextNode(nome));
    
    var td_b = document.createElement('td');
    td_b.appendChild(document.createTextNode(s[indice][1]));

    var td_c = document.createElement('td');
    td_c.appendChild(document.createTextNode(s[indice][2]));

    tr.appendChild(td_a)
    tr.appendChild(td_b)
    tr.appendChild(td_c)
    
    tbody.appendChild(tr)
  }

  tabela.append(tbody)

  return tabela
}