export var optionsGraph1 = {
  title: {
    text: "Distribuição por Regiões",
    align: "center",
    margin: -10,
    style: {
      fontSize: '20px',
      fontColor: "#000"
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '45%',
      endingShape: 'flat'
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  xaxis: {
    categories: ['Regiões'],
  },
  yaxis: {
    title: {
      text: 'Quantidade de ASNs'
    }
  },
  fill: {
    opacity: 1
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val + " ASNs"
      }
    }
  },
}

export var optionsGraph2 = {
  title: {
      text: "Distribuição por Estados",
      align: "center",
      margin: -10,
      style: {
          fontSize: '20px',
          fontColor: "#000"
      }
  },
  labels: [],
  colors: [],
  legend: {
      width: 100,
      height: '100%',
      position: 'right',
      offsetX: 150,
      itemMargin: {
          horizontal: 0,
          vertical: 5
      },
  },
  responsive: [{
      breakpoint: 1565,
      options: {
          legend: {
              offsetX: 100
          }
      }
  },{
      breakpoint: 1365,
      options: {
          legend: {
              offsetX: 50
          }
      }
  },{
      breakpoint: 1155,
      options: {
          legend: {
              offsetX: 0
          }
      }
  },{
      breakpoint: 992,
      options: {
          legend: {
              offsetX: 100
          }
      }
  },{
      breakpoint: 795,
      options: {
          legend: {
              offsetX: 20
          }
      }
  },{
      breakpoint: 520,
      options: {
          dataLabels: {
              style: {
                  fontSize: '9px'
              }
          },
          legend: {
              width: 95,
              height: 200,
              offsetX: 0
          },
      }
  }],
  tooltip: {
      y: {
          formatter: function(val) {
              return val + " ASNs"
          }
      }
  }
}