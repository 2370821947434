import React from 'react';

import PageHeader from '../../components/PageHeader/PageHeader';
import Mapa from '../../components/Mapa/Mapa';
import TabelaLocalizador from '../../components/TabelaLocalizador/index';

import {Container, Col, Row} from 'react-bootstrap';

import './styles.css'

import api from '../../services/api'

import { Marker, Popup } from 'react-leaflet';
import { blueMarker } from '../../components/CustomClustersMarkers/index'



class Localizador extends React.Component { 
	constructor(){
		super()

		this.state = {
			visualizacao: "mapa",
			lat: -13.7059886,
			lng: -69.6447028,
			zoom: 3,
			m: [],
			loc_total: 0,
			array_pagination: [],
			markers_data: [],
			list_layergroups: [],
			list_default: {
				lat: -13.7059886,
				lng: -69.6447028,
				zoom: 3
			},
		}
	}

	
	obter_total_asns = (cidades) => {
	
		if (cidades == null){
			return
		}

		var e=[];for(var a in cidades){var l=[cidades[a][0],cidades[a][1],Math.floor(cidades[a][2])];e.push(l)}for(var s=[],i=(e=e.sort(function(e,a){if(e[1]!==a[1])return a[1]-e[1]})).length,r=0;r<3;r++){for(var t=[],o=0;o<i;o++){var c=e[o][r];t.push(c)}s.push(t)}
			
		var array_pagination = [];
		var total = 0;

		for(var indice=0; indice < s[0].length; indice++){
			total += s[1][indice]
			array_pagination.push([s[0][indice], s[1][indice], s[2][indice]]);
		}

		this.setState({
			loc_total: total,
			array_pagination: array_pagination,
			m: cidades
		})
	}

	createMarkers = (a) => {
	    
	    var tamanho = a.length
	    var estatistica = [0, 0, tamanho]
	    
	    var list_markers = []
	    for(var r=0;r<tamanho;r++){
	        var lat=parseFloat(a[r][1])+.001*Math.random()
	        var long=parseFloat(a[r][2])+.001*Math.random()

	        list_markers.push(
		       <Marker key={r} position={[parseFloat(a[r][1]), parseFloat(a[r][2])]} icon={blueMarker}>
					<Popup>
						<b><p>as{a[r][0]}</p></b>
					</Popup>
				</Marker>
			)
			estatistica[0] += lat
	        estatistica[1] += long
	    }

		var resultado = {
			estatistica: estatistica, 
			markers_data: a,
			list_layergroups: list_markers
		}
		return resultado

	}

	definirZoom = (estatistica) => {
	    var media_lat = estatistica[0]
	    var media_long = estatistica[1]
	    var qtde_coord = estatistica[2]
	    var zoom
	    
	    if (qtde_coord !== 0) {
	        media_lat /= qtde_coord
	        media_long /= qtde_coord
	        zoom = 6
	    }
	    else{
	        media_lat = -13.7059886
	        media_long = -69.6447028
	        zoom = 3
	    }
	    return [media_lat, media_long, zoom]
	}


	FiltroASN = async (e) => { 
		if(e.target.id === "btn_limparFiltro"){
			document.getElementById('cep').value = 'CEP';
			document.getElementById('raio').value = '80';
			this.setState({
				list_layergroups: [],
				lat: this.state.list_default.lat,
				lng: this.state.list_default.lng,
				zoom: this.state.list_default.zoom,
			})
			return
		}
		var dados_zoom = null
		var resultado=  null

		var s={
			cep:document.getElementById("cep").value,
			raio:document.getElementById("raio").value
		};
		
		if (s["cep"].length !== 8 || parseInt(s['raio']) < 0 || isNaN(s['cep']) === true){
			
			return alert("CEP ou Raio inválido!")
		}
		var dados_request = s['raio'] + "_" + s["cep"];

		await api.get("/buscarceploc/" + dados_request + "/").then(response => {
			if(response.status !== 200){
				return alert('Erro ao fazer a pesquisa!');
			}
			var n = response.data

			if (n === 'CEP não encontrado!') {
				return alert('CEP não encontrado!')
			}

			var m=Object.values(n[0])
			n=Object.values(n[1])

			resultado = this.createMarkers(n)
			dados_zoom = this.definirZoom(resultado.estatistica)
			this.obter_total_asns(m)
		
			this.setState({
				lat: dados_zoom[0],
				lng: dados_zoom[1],
				zoom: dados_zoom[2],
				markers_data: resultado.markers_data,
				list_layergroups: resultado.list_layergroups,
			})
			
		}).catch(error => {
			alert(error.response.data)
			return
		})
		return
	}

	filtroOnClick = async (e) => {
		const {lat, lng} = e.latlng;
		const raio = document.getElementById("raio").value;

		await api.get('/buscarceplatlng', {
			params: {
				lat,
				lng,
				raio
			}
		}).then(response => {
			if(response.status !== 200){
				return alert('Erro ao fazer a pesquisa!');
			}
			var n = response.data
			var m=Object.values(n[0])
			n=Object.values(n[1])

			var resultado = [];
			var dados_zoom = [];

			if (n !== 'CEP não encontrado!'){
				resultado = this.createMarkers(n)
				dados_zoom = this.definirZoom(resultado.estatistica)
				this.obter_total_asns(m)
			}
		
			this.setState({
				lat: dados_zoom[0],
				lng: dados_zoom[1],
				zoom: dados_zoom[2],
				markers_data: resultado.markers_data,
				list_layergroups: resultado.list_layergroups,
			})

			return
		}).catch(error => {
			alert("Erro ao realizar a busca dos ASNs.")
			return
		  })
	}

	render(){
		return (
			<div>
			  <Container className="m-0 p-0" fluid>
			    <Row className="m-0">
			      <Col className=" p-0 menu text-center">
			        <PageHeader/>
			      </Col>
			      <Col xl={10} lg={10} md={10} className="p-0">
					<div className="col-sm-12 col-md-12 col-lg-12 my-3 text-center">
						<button className="btn btn-sm btn-outline-dark w-25" id="btn_mapa_localizador" onClick={() => this.setState({visualizacao: "mapa"})}>Visualizar Mapa</button>
						<button className="btn btn-sm btn-outline-dark w-25" id="btn_tabela_localizador" onClick={() => this.setState({visualizacao: "tabela"})}>Visualizar Tabela</button>
					</div>
			        { this.state.visualizacao === "mapa" ? <Mapa
			          markersData={this.state.markers_data}
			          dataMap={
			            [
			              this.state.list_layergroups,
			              this.state.lat,
						  this.state.lng,
						  this.state.zoom
			            ]
			          }
					  funcaoFiltro = {this.FiltroASN}
					  filtroOnClick = {this.filtroOnClick}
			          page="Localizador"
			        /> : 
					  <TabelaLocalizador
				         loc_total = {this.state.loc_total}
				         array_pagination = {this.state.array_pagination}
				         m = {this.state.m}
			          />
					}
			      </Col>
			    </Row>
			  </Container>
			</div>
		)
	}
}

export default Localizador;