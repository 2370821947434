import React from 'react';
import {Link} from 'react-router-dom';

import Select from '../Select/index'

import info_icon from '../../assets/img/info-icon.png'

import './styles.css'

class Filtros extends React.Component{	

	componentDidMount() {
		if(this.props.pageFiltro === "Localizador"){

			document.getElementById("cep").addEventListener("keyup", function(event){
				if (event.keyCode === 13){
					event.preventDefault();
					document.getElementById("btn_localizador").click()
				}
			})
		
			document.getElementById("raio").addEventListener("keyup", function(event){
				if (event.keyCode === 13){
					event.preventDefault();
					document.getElementById("btn_localizador").click()
				}
			})
		} else if(this.props.pageFiltro === "GeoIP") {
			document.getElementById("asn").addEventListener("keyup", function(event){
				if (event.keyCode === 13){
					event.preventDefault();
					document.getElementById("btn_localizador").click()
				}
			})
		}
	}

	render(){
		if(this.props.pageFiltro === "MainPage"){
			return(
				<div className="filtro_form">
					<div id="titulo_filtro">
						<span id="faq"><Link to="/FAQ"><img src={info_icon} alt="info_icon"/></Link></span>
						<h4 className="h4 text-center">Filtro</h4>
					</div>
					
					<button onClick={this.props.funcaoFiltro} id="btn_limparFiltro" className="btn btn-block btn-outline-dark btn-sm my-1">Limpar Filtro</button>
					<Select mapa="MainPage" funcao={this.props.funcaoFiltro} />
				</div>
			)
		}
		else if(this.props.pageFiltro === "PTTs"){
			return(
				<div className="filtro_form">
					<div id="titulo_filtro">
						<span id="faq"><Link to="/FAQ"><img src={info_icon} alt="info_icon"/></Link></span>
						<h4 className="h4 text-center">Filtro de PTTs</h4>
					</div>
					<button onClick={this.props.funcaoFiltro} id="btn_limparFiltro" className="btn btn-block btn-outline-dark btn-sm my-1">Limpar Filtro</button>
					<Select mapa="PTTs" funcao={this.props.funcaoFiltro} />
					<select defaultValue="marker" className="form-control form-control-sm" name="optPlotagem" id="optPlotagem" onChange={this.props.funcaoVisualizacao}>
						<option value="selecione">Opções de Exibição:</option>
						<option value="marker">Marcadores</option>
						<option value="circulo">Círculos</option>
					</select>
				</div>
			)
		}
		else if(this.props.pageFiltro === "Localizador"){
			return(
				<div className='localizador_form'>
					<div id="titulo_filtro">
						<span id="faq"><Link to="/FAQ"><img src={info_icon} alt="info_icon"/></Link></span>
						<h4 className="h4 text-center">Localizador</h4>
					</div>
					<button onClick={this.props.funcaoFiltro} id="btn_limparFiltro" className="btn btn-block btn-outline-dark btn-sm my-1">Limpar Filtro</button>
					<button className="btn btn-block btn-outline-dark btn-sm my-1" id="btn_localizador" onClick={this.props.funcaoFiltro}>Localizar</button>
					<input className="form-control form-control-sm mb-1" type="number" name="cep" id="cep" min="0" max="99999999" maxLength="8" placeholder="CEP"></input>
					<div className="input-group input-group-sm">
						<div className="input-group-prepend">
							<div className="input-group-text">Raio (km)</div>
							<input className="form-control form-control-sm" type="number" name="raio" id="raio" min="0" defaultValue="80"></input>
						</div>
					</div>
				</div>
			)
		}
		else if(this.props.pageFiltro === "GeoIP") {
			return(
				<div className='localizador_form w-100'>
					<div id="titulo_filtro">
						<span id="faq"><Link to="/FAQ"><img src={info_icon} alt="info_icon"/></Link></span>
						<h4 className="h4 text-center">Localizador de blocos IPs</h4><br/>
					</div>
					<button id="btn_limparFiltro" className="btn btn-block btn-outline-dark btn-sm my-1" onClick={this.props.funcaoLimparFiltro}>Limpar Filtro</button>
					<button className="btn btn-block btn-outline-dark btn-sm my-1" id="btn_localizador" onClick={this.props.funcaoFiltro}>Localizar</button>
					<input className="form-control form-control-sm mb-1" type="text" name="asn" id="asn" placeholder="ASN"></input>
					<select defaultValue="marker" className="form-control form-control-sm" name="optPlotagem" id="optPlotagem" onChange={this.props.funcaoVisualizacao}>
						<option value="selecione">Opções de Exibição:</option>
						<option value="marker">Marcadores</option>
						<option value="UFs">Visualização por estados</option>
						<option value="cidades">Visualização por cidades</option>
					</select>
				  </div>
			  )
		}
		else if(this.props.pageFiltro === "IPsLGs") {
			return(
				<div className='localizador_form w-100'>
					<h4 className="h4 text-center">Filtro de IPs LGs</h4><br/>
					<button id="btn_limparFiltro" className="btn btn-block btn-outline-dark btn-sm my-1" onClick={this.props.funcaoLimparFiltro}>Limpar Filtro</button>
					<Select mapa="IPsLGs" funcao={this.props.funcaoFiltro} />
					<select className="form-control form-control-sm" name="optPlotagem" id="optPlotagem" onChange={this.props.funcaoVisualizacao}>
						<option value="selecione">Opções de Exibição:</option>
						<option value="marker" defaultValue>Marcadores</option>
						<option value="UFs">Visualização por estados</option>
					</select>
				  </div>
			  )
		}
	}

}

export default Filtros;