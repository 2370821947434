import React from 'react';

import './styles.css'

function Select(props){
	
	var estados = {
		'selecione': 'Selecione',
		'ac': 'Acre',
		'al': 'Alagoas',
		'ap': 'Amapá',
		'am': 'Amazonas',
		'ba': 'Bahia',
		'ce': 'Ceará',
		'df': 'Distrito Federal',
		'es': 'Espírito Santo',
		'go': 'Goiás',
		'ma': 'Maranhão',
		'mt': 'Mato Grosso',
		'ms': 'Mato Grosso do Sul',
		'mg': 'Minas Gerais',
		'pa': 'Pará',
		'pb': 'Paraíba',
		'pr': 'Paraná',
		'pe': 'Pernambuco',
		'pi': 'Piauí',
		'rj': 'Rio de Janeiro',
		'rn': 'Rio Grande do Norte',
		'rs': 'Rio Grande de Sul',
		'ro': 'Rondônia',
		'rr': 'Roraima',
		'sc': 'Santa Catarina',
		'sp': 'São Paulo',
		'se': 'Sergipe',
		'to': 'Tocantins'
	}

	var localidades = {
		'se': 'Aracaju/SE',
		'bel': 'Belém/PA',
		'mg': 'Belo Horizonte/MG',
		'rr': 'Boa Vista/RR',
		'df': 'Brasília/DF',
		'cac': 'Cascavel/PR',
		'cpv': 'Campina Grande/PB', 
		'cas': 'Campinas/SP',
		'cgr': 'Campo Grande/MS',
		'cxj': 'Caxias do Sul/RS',
		'cgb': 'Cuiabá/MT',
		'pr': 'Curitiba/PR', 
		'sc': 'Florianópolis/SC',
		'ce': 'Fortaleza/CE', 
		'igu': 'Foz do Iguaçu/PR', 
		'gyn': 'Goiânia/GO', 
		'jpa': 'João Pessoa/PB', 
		'laj': 'Lajeado/LAJ', 
		'lda': 'Londrina/PR', 
		'mcz': 'Maceió/AL', 
		'mao': 'Manaus/AM',
		'mgf': 'Maringá/PR', 
		'nat': 'Natal/RN',
		'to': 'Palmas/TO', 
		'rs': 'Porto Alegre/RS',
		'pe': 'Recife/PE', 
		'rj': 'Rio de Janeiro/RJ',
		'ba': 'Salvador/BA',
		'ria': 'Santa Maria/RS', 
		'sjc': 'São José dos Campos/SP',
		'sjp': 'São José do Rio Preto/SP',
		'slz': 'São Luís/MA',
		'sp': 'São Paulo/SP',
		'the': 'Teresina/PI', 
		'vix': 'Vitória/ES'
	}

	if(props.mapa === "MainPage"){
		return(
			<select onChange={props.funcao} className="form-control form-control-sm" name="estado" id="estado">{estados && Object.keys(estados).map(key=>{return <option key={key} value={key}>{estados[key]}</option>})}
			</select>
		)
	} 
	else if(props.mapa === "PTTs") {
		return(
			<div>
				<select onChange={props.funcao} className="form-control form-control-sm" name="ptt" id="ptt">
					<option key="selecione" value="selecione">Selecione um PTT</option>
					{localidades && Object.keys(localidades).map(key => {
						return <option key={key} value={key}>{localidades[key]}</option>
					})}
				</select>
				<select onChange={props.funcao} className="form-control form-control-sm" name="ptt2" id="ptt2">
					<option key="selecione" value="selecione">Selecione outro PTT (opcional)</option>
					{localidades && Object.keys(localidades).map(key => {
						return <option key={key} value={key}>{localidades[key]}</option>
					})}
				</select>
			</div>
		)
	}
	else if(props.mapa === "IPsLGs") {
		return(
			<div>
				<select onChange={props.funcao} className="form-control form-control-sm" name="ptt" id="ptt">
					<option key="selecione" value="selecione">Selecione um PTT</option>
					{localidades && Object.keys(localidades).map(key => {
						return <option key={key} value={key}>{localidades[key]}</option>
					})}
				</select>
			</div>
		)
	}
}

export default Select;