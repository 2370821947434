import L from 'leaflet';
import 'leaflet-extra-markers';
import 'leaflet.markercluster';

export var clusterMarkersPtt1 = function (cluster) {
    var childCount = cluster.getChildCount();
    var c = ' asns_ptt1 marker-cluster-';
    if (childCount < 10) {
        c += 'small';
    } 
    else if (childCount < 100) {
        c += 'medium';
    } 
    else {
        c += 'large';
    }
  
    return new L.DivIcon({ html: '<div><span>' + childCount + '</span></div>', 
    className: 'marker-cluster' + c, iconSize: new L.Point(40, 40) });
};

export var clusterMarkersPtt2 = function (cluster) {
    var childCount = cluster.getChildCount();
    var c = ' asns_ptt2 marker-cluster-';
    if (childCount < 10) {
        c += 'small';
    } 
    else if (childCount < 100) {
        c += 'medium';
    } 
    else {
        c += 'large';
    }
  
    return new L.DivIcon({ html: '<div><span>' + childCount + '</span></div>', 
    className: 'marker-cluster' + c, iconSize: new L.Point(40, 40) });
};

export var clusterMarkersPtt3 = function (cluster) {
    var childCount = cluster.getChildCount();
    var c = ' asns_ptt1_ptt2 marker-cluster-';
    if (childCount < 10) {
        c += 'small';
    } 
    else if (childCount < 100) {
        c += 'medium';
    } 
    else {
        c += 'large';
    }
  
    return new L.DivIcon({ html: '<div><span>' + childCount + '</span></div>', 
    className: 'marker-cluster' + c, iconSize: new L.Point(40, 40) });
};

export var blueMarker = L.ExtraMarkers.icon({
    markerColor: "blue",
    shape: 'circle',
    prefix: 'fa',
});

export var redMarker = L.ExtraMarkers.icon({
    markerColor: "orange-dark",
    shape: 'circle',
    prefix: 'fa',
});

export var greenMarker = L.ExtraMarkers.icon({
    markerColor: "green-light",
    shape: 'circle',
    prefix: 'fa',
});