import React from 'react';
//import api from '../../services/api'

import L from 'leaflet';
import 'leaflet-extra-markers';
import 'leaflet.markercluster';

import { Map, TileLayer, Marker, /* Popup,*/ Pane } from 'react-leaflet';

import MarkerClusterGroup from "react-leaflet-markercluster";

import Control from 'react-leaflet-control';

import Filtros from '../Filtros/index'

import './styles.css'
import 'leaflet/dist/leaflet.css'

import 'react-leaflet-markercluster/dist/styles.min.css'

import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css'


class Mapa extends React.Component {
  constructor(){
    super()

    this.ref = React.createRef();
  }

  render(){
    
    //Página 'Sede do AS'
    if(this.props.page === "MainPage"){
      return(
        <Map id="mapid" className="markercluster-map" center={[this.props.dataMap[1], this.props.dataMap[2]]} zoom={this.props.dataMap[3]}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Control position="topright">
            <Filtros pageFiltro={this.props.page} funcaoFiltro={this.props.funcaoFiltro} />
          </Control>
          { this.props.dataMap[0].length !== 0 && <MarkerClusterGroup>{this.props.dataMap[0]}</MarkerClusterGroup>}
        </Map>
      )
    }

    //Página 'Presença nos PTTs'
    else if(this.props.page === 'PTTs') {
      return(
        <Map id="mapid" className="markercluster-map" center={[this.props.dataMap[0], this.props.dataMap[1]]} zoom={this.props.dataMap[2]}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <Control position="topright">
            <Filtros
              pageFiltro={this.props.page}
              funcaoFiltro={this.props.funcaoPTT}
              funcaoVisualizacao={(e) => this.props.funcaoVisualizacao(e)}
            />
          </Control>
          
          <Pane style={{zIndex: 500}}>{ this.props.ptts }</Pane>
          <Pane style={{zIndex: 600}}>{this.props.plotData.length !== 0 && this.props.plotData}</Pane>

        </Map>
      )
    }
    //Página 'Localizador'
    else if(this.props.page === 'Localizador'){
      return(
        <Map onclick={this.props.filtroOnClick} id="mapid" className="markercluster-map" center={[this.props.dataMap[1], this.props.dataMap[2]]} zoom={this.props.dataMap[3]}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Control position="topright" className="w-75">
            <Filtros pageFiltro={this.props.page} funcaoFiltro={this.props.funcaoFiltro} />
          </Control>
          { this.props.dataMap[0].length !== 0 && <MarkerClusterGroup>{this.props.dataMap[0]}</MarkerClusterGroup>}
        </Map>
      )
    }
    //Página 'GeoIP'
    else if(this.props.page === 'GeoIP'){
      return(
        <Map ref={this.ref} preferCanvas={true} onclick={this.props.filtroOnClick} id="mapid" className="markercluster-map" center={[this.props.dataMap[1], this.props.dataMap[2]]} zoom={this.props.dataMap[3]}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Control position="topright" className="w-75">
            <Filtros 
              pageFiltro={this.props.page} 
              funcaoFiltro={() => this.props.funcaoFiltro(this.ref)} 
              funcaoVisualizacao={(e) => this.props.funcaoVisualizacao(e, this.ref)} 
              funcaoLimparFiltro={() => this.props.funcaoLimparFiltro(this.ref)} />
          </Control>
          { this.props.plotData.length !== 0 && this.props.plotData }
        </Map>
      )
    }
    //Página 'IPsLGs'
    else if(this.props.page === 'IPsLGs'){
      return(
        <Map ref={this.ref} preferCanvas={true} onclick={this.props.filtroOnClick} id="mapid" className="markercluster-map" center={[this.props.dataMap[1], this.props.dataMap[2]]} zoom={this.props.dataMap[3]}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Control position="topright" className="w-75">
            <Filtros 
              pageFiltro={this.props.page} 
              funcaoFiltro={() => this.props.funcaoFiltro(this.ref)} 
              funcaoVisualizacao={(e) => this.props.funcaoVisualizacao(e, this.ref)} 
              funcaoLimparFiltro={() => this.props.funcaoLimparFiltro(this.ref)} />
          </Control>
          { this.props.plotData.length !== 0 && this.props.plotData }
          { this.props.ptts.length !== 0 && this.props.ptts }
        </Map>
      )
    }

    else {
      return(
        <Map id="mapid" className="markercluster-map" center={[this.props.dataMap[0], this.props.dataMap[1]]} zoom={this.props.dataMap[2]}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </Map>
      )
    }
  }
}

export default Mapa;