import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';

import MainPage from './pages/MainPage/index'
import Graficos from './pages/Graficos/index'
import Tabela from './pages/Tabela/index'
import Localizador from './pages/Localizador/index'
import PTTs from './pages/PTTs/index'
import GeoIP from './pages/GeoIP/index'
//import IPsLGs from './pages/IPsLGs/index'
import FAQ from "./pages/FAQ/index"

function Routes() {
    return(
        <BrowserRouter>
            <Route exact path="/" component={MainPage}/>
            <Route exact path="/graficos" component={Graficos}/>
            <Route exact path="/tabela" component={Tabela}/>
            <Route exact path="/localizador" component={Localizador}/>
            <Route exact path="/PTTs" component={PTTs}/>
            <Route exact path="/geoIP" component={GeoIP}/>
            {/*<Route exact path="/IPsLGs-v2" component={IPsLGs}/>*/}
            <Route exact path="/FAQ" component={FAQ}/>
        </BrowserRouter>
    )
}

export default Routes;