import React from 'react';
import api from '../../services/api'

import PageHeader from '../../components/PageHeader/PageHeader';
import Mapa from '../../components/Mapa/Mapa';

import {Container, Col, Row} from 'react-bootstrap';
import { Marker, Popup, LayerGroup } from 'react-leaflet';

import L from 'leaflet';
import 'leaflet-extra-markers';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import './styles.css'

var blueMarker = L.ExtraMarkers.icon({
	markerColor: "blue",
	shape: 'circle',
	prefix: 'fa',
  });

class MainPage extends React.Component {
	constructor() {
		super()

		this.state = {
			markersData: [],
			list_defaultLayerGroups: {
				list_layergroups: [],
				lat: 0,
				lng: 0
			},
			list_layergroups: [],
			lat: -13.7059886,
			lng: -69.6447028,
			zoom: 3,
			latLng_ufs: {}
		}
	}

	FiltroEstado = (e) => {
		if(e.target.id === "btn_limparFiltro"){
			this.setState({
				list_layergroups: this.state.list_defaultLayerGroups.list_layergroups,
				lat: this.state.list_defaultLayerGroups.lat,
				lng: this.state.list_defaultLayerGroups.lng,
				zoom: 6
			})
			document.getElementById('estado').value = 'selecione';
			return
		}
		
		this.state.list_defaultLayerGroups.list_layergroups.forEach(layer_group => {
			if(layer_group["key"] === e.target.value.toUpperCase()) {
				var latLng = this.state.latLng_ufs[layer_group["key"]]
				this.setState({
					list_layergroups: layer_group,
					lat: latLng[0],
					lng: latLng[1]
				})
			}
		});
		return
	}

	async componentDidMount(){
		await api.get('/buscarasn/').then(response => {
			
			var list_layergroups = [];
			var asns=response.data;
			var lat = 0, lng = 0, media_lat = 0, media_lng = 0, qtde_coord = 0;
			var latLng_ufs = {};

			for(var asns_uf in asns) {
				var lat_uf = 0;
				var lng_uf = 0;
				var list_markers = [];
				
				for(var indice_asn in asns[asns_uf]){
					var asn = asns[asns_uf][indice_asn]
					lat += parseFloat(asn[1]);
					lat_uf += parseFloat(asn[1]);
					lng += parseFloat(asn[2]);
					lng_uf += parseFloat(asn[2]);
					qtde_coord += 1;
					list_markers.push(
						<Marker key={asn[4]} position={[parseFloat(asn[1]), parseFloat(asn[2])]} icon={blueMarker}>
							<Popup>
								<b>
									<p>as{asn[0]}</p>
								</b>
							</Popup>
						</Marker>
					)
				}

				latLng_ufs[asns_uf] = [lat_uf /= asns[asns_uf].length, lng_uf /= asns[asns_uf].length] 
				list_layergroups.push(<LayerGroup key={asns_uf}>{list_markers}</LayerGroup>)
			}

			media_lat = lat /= qtde_coord
			media_lng = lng /= qtde_coord
			
			this.setState({
				markersData: response.data,
				list_defaultLayerGroups: {
					list_layergroups: list_layergroups,
					lat: media_lat,
					lng: media_lng
				},
				list_layergroups: list_layergroups,
				lat: media_lat,
				lng: media_lng,
				zoom: 6,
				latLng_ufs: latLng_ufs
			})
		}).catch(error => {
			alert("Erro ao buscar dados dos ASNs.")
			return
		})
	}
	
	render() {
		return(
			<div>
				<Container className="m-0 p-0" fluid>
					<Row className="m-0">
						<Col className=" p-0 menu text-center">
							<PageHeader/>
						</Col>
						<Col xl={10} lg={10} md={10} className="p-0">
							<Mapa
								markersData={this.state.markersData}
								dataMap={[
									this.state.list_layergroups,
									this.state.lat,
									this.state.lng,
									this.state.zoom,
									this.state.latLng_ufs
								]}
								page="MainPage"
								funcaoFiltro={this.FiltroEstado}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}


export default MainPage;