import React from 'react';

import {Container, Col, Row} from 'react-bootstrap';

import PageHeader from '../../components/PageHeader/PageHeader';

import './styles.css'

class FAQ extends React.Component {
  	render(){
		return(
			<div>
				<Container className="m-0 p-0" fluid>
					<Row className="m-0">
						<Col className=" p-0 menu text-center">
							<PageHeader />
						</Col>
						<Col xl={10} lg={10} md={10} className="p-0">
							<div id="div_faq" className="text-justify">
								<div className="mb-5">
									<h4>Mapa Sedes dos ASNs</h4>
									<p>
										Este mapa exibe a localização aproximada da sede dos Sistemas Autônomos brasileiros (ASNs atribuídos pelo Registro.br). O endereço da sede foi estimado pelo CEP cadastrado no Registro.br e é exibido de forma anonimizada. A informação de geolocalização de cada CEP foi obtida pela API do OpenStreetMaps e pela API PyCEPCorreios.
									</p>
								</div>
								<div className="mb-5">
									<h4>Mapa Presença nos PTTs</h4>
									<p>
										Este mapa exibe a localização aproximada da sede dos Sistemas Autônomos brasileiros (ASNs atribuídos pelo Registro.br) presentes em cada um dos PTTs do IX.br. Essa localização é obtida da mesma forma que no mapa “Sedes dos ASNs”.
									</p>
									<p>
										O mapa permite também a comparação entre dois PTTs diferentes do IX.br. Escolhendo um PTT no menu, o mapa exibe a localização aproximada da sede dos ASNs brasileiros lá presentes. Escolhendo um segundo PTT, o mapa exibe com a cor azul os ASNs presentes no primeiro PTT, em vermelho os ASNs presentes no segundo PTT, e em verde os ASNs presentes em ambos simultaneamente.
									</p>
								</div>
								<div className="mb-5">
									<h4>Localizador de ASNs</h4>
									<p>
										Neste serviço é possível localizar ASNs presentes em um determinado raio, à partir de uma localização dada. Essa localização pode ser fornecida de duas formas: digitando um CEP no campo correspondente, ou clicando na localização desejada no próprio mapa.
									</p>
									<p>
										A localização da sede dos ASNs é obtida da mesma forma que no mapa “Sedes dos ASNs”. A visualização se por meio de um mapa, em que os ASNs são mostrados de forma anonimizada, e por meio de uma tabela, na qual constam os municípios presentes no raio escolhido e a quantidade de ASNs em cada um deles.
									</p>
								</div>
								<div className="mb-5">
									<h4>Localizador de Blocos IPv4</h4>
									<p>
										Neste mapa é possível visualizar os blocos IPv4 de um ASN brasileiro, fornecido como parâmetro. A lista de blocos IPv4 é obtida a partir do arquivo disponibilizado publicamente pelo Registro.br em https://ftp.registro.br/pub/numeracao/origin/nicbr-asn-blk-latest.txt. Esses blocos são separados então em /24s. Para o IP n.n.n.1 de cada /24 a geolocalização é obtida a partir de consulta à base de dados GeoLite2 Free Geolocalization Data da empresa MaxMind, disponível em <a href="https://dev.maxmind.com/geoip/geoip2/geolite2/" target="_blank" rel="noopener noreferrer">https://dev.maxmind.com/geoip/geoip2/geolite2/.</a>
									</p>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
		  	</div>
		)
  	}
}

export default FAQ;