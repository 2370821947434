import React from 'react';
import api from '../../services/api'

import PageHeader from '../../components/PageHeader/PageHeader';
import Mapa from '../../components/Mapa/Mapa';

//import MarkerClusterGroup from "react-leaflet-markercluster";

import {Container, Col, Row} from 'react-bootstrap';
import { /*Marker,*/ Popup, GeoJSON } from 'react-leaflet';

import { blueMarker } from '../../components/CustomClustersMarkers/index'

import L from 'leaflet';
import 'leaflet-extra-markers';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import './styles.css'

import $ from 'jquery';

/*
var opacities = {
	100: 0.1,
	200: 0.2,
	300: 0.3,
	400: 0.4,
	500: 0.5,
	600: 0.6,
	700: 0.7,
}
*/
function getColor(d) {
    return d > 1000 ? '#000066' :
           d > 500  ? '#000099' :
           d > 200  ? '#0000cc' :
           d > 100  ? '#0000ff' :
           d > 50   ? '#3232ff' :
           d > 20   ? '#6666ff' :
           d > 10   ? '#9999ff' :
                      '#ccccff';
}

class GeoIP extends React.Component {
	constructor() {
		super()

		this.state = {
			plotData: [],
			markerCluster: [],
			poliesCidades: [],
			poliesEstados: [],
			lat: -13.7059886,
			lng: -69.6447028,
			zoom: 3,
		}
	}

	limparFiltro = (ref) => {
		this.setState({
			plotData: [],
			markerCluster: [],
			poliesCidades: [],
			poliesEstados: [],
			lat: -13.7059886,
			lng: -69.6447028,
			zoom: 3,
		})

		var mapRef = ref.current.leafletElement;
		mapRef.removeLayer(this.state.markerCluster)

		document.getElementById('asn').value = "";
		document.getElementById("optPlotagem").value = "marker";

		return
	}

	definirZoom = (estatistica) => {
	    var media_lat = estatistica[0]
	    var media_long = estatistica[1]
	    var qtde_coord = estatistica[2]
	    var zoom
	    
	    if (qtde_coord !== 0) {
	        media_lat /= qtde_coord
	        media_long /= qtde_coord
	        zoom = 6
	    }
	    else{
	        media_lat = -13.7059886
	        media_long = -69.6447028
	        zoom = 3
	    }
	    return [media_lat, media_long, zoom]
	}

	buscaPoligonos = async (data, type) => {

		var polies_count = {};
		for(var item in data){
			if( type === "cidades"){
				polies_count[data[item]["poligono_cidade"]] = data[item]["poligono_cidade__count"]
			} else {
				polies_count[data[item]["poligono_estado"]] = data[item]["poligono_estado__count"]
			}
		}

		var list_polies = [];
		
		await api.post('/buscaPolyGeoIP/', data).then(response => {
			var polies = response.data;
			polies = polies["features"];
			for(var i = 0; i < polies.length; i++){
				var count_poly = polies_count[polies[i]["id"]]

				var style = {
					opacity: 1,
					dashArray: '3',
					fillColor: getColor(count_poly),
					weight: 2,
					color: 'white',
					fillOpacity: 0.7
				}

				list_polies.push(<GeoJSON key={polies[i]["id"]} data={polies[i]["geometry"]} style={style}>
					<Popup>
						<b>{count_poly} bloco(s) IP(s) /24</b>
					</Popup>
				</GeoJSON>)
			}

		}).catch(error => {
			alert("Erro ao buscar polígonos de " + type + ".")
			return
		})

		return list_polies
	}

	FiltroGeoIP = async (ref) => {
		var map = ref.current.leafletElement;
		map.removeLayer(this.state.markerCluster)

		var asn = document.getElementById('asn').value;
		var plotOption = document.getElementById("optPlotagem").value;

		if(asn === ""){
			return
		}

		await api.get("/buscaGeoIP/" + asn + "/").then(async response => {
			if(response.status !== 200){
				return alert('Erro ao fazer a pesquisa!');
			}

			var r = response.data;

			if("erro" === Object.keys(r)[0]){
				alert(r["erro"])
				$('.alert').hide();
				return
			}
			
			var geoloc=r[0];
			var estados=r[1];
			var cidades=r[2];

			var markerCluster = new L.markerClusterGroup();
			var estatistica = [0, 0, geoloc.length];
			
			for(var t=0;t<geoloc.length;t++){
				var lat=parseFloat(geoloc[t][1])+.001*Math.random()
				var long=parseFloat(geoloc[t][2])+.001*Math.random();
				var e=L.marker(new L.LatLng(lat,long), {icon: blueMarker});

				var bloco = geoloc[t][0]
				bloco = bloco.replace('_', '.').replace('_', '.').replace('_', '.').replace('_', '/')
				e.bindPopup("<b>"+bloco+"</b>")
				
				markerCluster.addLayer(e)
				estatistica[0] += lat
				estatistica[1] += long

			}

			estados = await this.buscaPoligonos(estados, "estados");
			cidades = await this.buscaPoligonos(cidades, "cidades");
			var dados_zoom = this.definirZoom(estatistica);

			var plotData = "";

			if(plotOption === "UFs"){
				plotData = estados;
			}
			else if(plotOption === "cidades"){
				plotData = cidades;
			}
			else{
				map.addLayer(markerCluster);

				this.setState({
					poliesEstados: estados,
					poliesCidades: cidades,
					plotData: [],
					markerCluster: markerCluster,
					lat: dados_zoom[0],
					lng: dados_zoom[1],
					zoom: dados_zoom[2],
				})

				return
			}

			this.setState({
				poliesEstados: estados,
				poliesCidades: cidades,
				plotData: plotData,
				markerCluster: markerCluster,
				lat: dados_zoom[0],
				lng: dados_zoom[1],
				zoom: dados_zoom[2],
			})

			return
		}).catch(error => {
			alert("Erro ao buscar blocos IPs do ASN " + asn + ".")
			return
		})
	}

	optVisualizacao = (e, ref) => {

		if(e.target.value === "selecione" || this.state.markerCluster.length === 0 || this.state.poliesCidades.length === 0 || this.state.poliesEstados.length === 0){
			return
		}

		var mapRef = ref.current.leafletElement;
		mapRef.removeLayer(this.state.markerCluster)

		var plotOption = e.target.value;
		var plotData = "";
		
		if(plotOption === "UFs"){
			plotData = this.state.poliesEstados;
		}
		else if(plotOption === "cidades"){
			plotData = this.state.poliesCidades;
		}
		else{
			mapRef.addLayer(this.state.markerCluster)
			this.setState({
				plotData: []
			})
			return
		}

		this.setState({
			plotData: plotData,
		})

		return
	}

	render(){
		return(
			<div>
				<Container className="m-0 p-0" fluid>
					<Row className="m-0">
						<Col className=" p-0 menu text-center">
							<PageHeader/>
						</Col>
						<Col xl={10} lg={10} md={10} className="p-0">
							<Mapa
								markersData={this.state.markersData}
								dataMap={[
									this.state.markerCluster,
									this.state.lat,
									this.state.lng,
									this.state.zoom,
									this.state.latLng_ufs
								]}
								page="GeoIP"
								funcaoFiltro={this.FiltroGeoIP}
								funcaoLimparFiltro={this.limparFiltro}
								funcaoVisualizacao={this.optVisualizacao}
								plotData={this.state.plotData}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

export default GeoIP;