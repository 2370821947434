import React from 'react';
import api from '../../services/api'

import PageHeader from '../../components/PageHeader/PageHeader';

import {Container, Col, Row} from 'react-bootstrap';

import Chart from 'react-apexcharts';

import { optionsGraph1, optionsGraph2 } from './graphOptions'

import './styles.css'

function getRandomColor() {
    for (var o = "0123456789ABCDEF".split(""), e = "#", t = 0; t < 6; t++) e += o[Math.floor(16 * Math.random())];
    return e;
}

class Graficos extends React.Component {
  constructor(){
    super()

    this.state = {
      total_asns: 0,
      data_atualizacao: 0,
      grafico_regioes: {
        options: [],
        series: []
      },
      grafico_estados: {
        options: [],
        series: []
      }
    };
  }

  async componentDidMount(){

    /* Buscando total de ASNs e última data de atualização */
    await api.get('/buscatotaldata/').then(response => {
      this.setState({
        total_asns: response.data[0],
        data_atualizacao: response.data[1],
      })
    }).catch(error => {
      alert("Erro ao buscar total de ASNs.")
      return
    });

    /* Buscando dados para o gráfico de regiões */
    await api.get('/buscaregiao/').then(response => {
      
      var dados = response.data;
      dados = Object.values(dados)

      var series = [{
        name: "Norte (" + dados[0] + ")",
        data: [dados[0]]
      }, {
        name: "Centro-Oeste (" + dados[1] + ")",
        data: [dados[1]]
      }, {
        name: "Sul (" + dados[2] + ")",
        data: [dados[2]]
      }, {
        name: "Nordeste (" + dados[3] + ")",
        data: [dados[3]]
      }, {
        name: "Sudeste (" + dados[4] + ")",
        data: [dados[4]]
      }]

      this.setState({
        grafico_regioes: {
          options: optionsGraph1,
          series: series
        }
      })
    }).catch(error => {
      alert("Erro ao buscar dados para o de regiões.")
      return
    });

    /* Buscando dados para o gráfico de estados */
    await api.get("/buscaestado/").then(response => {
      var dados = response.data;
      for (var o = dados[0], e = dados[1], t = [], a = [], s = 0; s < o.length; s++) t.push(o[s] + "(" + e[s] + ")");
      for (s = 0; s < e.length; s++) a.push(getRandomColor());

      var options = optionsGraph2;
      options.labels = t;
      options.colors = a;

      this.setState({
        grafico_estados: {
          options: options,
          series: e
        }
      })

    }).catch(error => {
      alert("Erro ao buscar dados para o de estados.")
      return
    })
  }

  render(){
  return (
    <div>
      <Container className="m-0 p-0" fluid>
        <Row className="m-0">
          <Col className=" p-0 menu text-center">
            <PageHeader/>
          </Col>
          <Col id="content" xl={10} lg={10} md={10} className="p-0">
            <Row className="m-0">
              <Col className="p-0 my-5 text-center">
                <h2>Total de ASNs: {this.state.total_asns}</h2>
                <b><p>Última Atualização: {this.state.data_atualizacao}</p></b>
              </Col>
            </Row>
            <Row className="m-0">
              <Col lg={{span: 7, offset: 2}} className="p-0 mb-5">
                <div className="w-100 h-100 text-center">
                  { this.state.grafico_regioes.options.length !== 0 && <Chart
                    options={this.state.grafico_regioes.options}
                    series={this.state.grafico_regioes.series}
                    type="bar"
                    width="100%"
                    height="350"
                  /> }
                </div>
              </Col>
            </Row>
            <Row className="m-0">
              <Col lg={{span: 7, offset: 2}} className="p-0">
                <div className="w-100 h-100">
                  { this.state.grafico_estados.options.length !== 0 && <Chart
                    options={this.state.grafico_estados.options}
                    series={this.state.grafico_estados.series}
                    type="pie"
                    width="100%"
                    height="350"
                  /> }
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )};
}

export default Graficos;